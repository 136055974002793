/* eslint-disable react-native/no-inline-styles */

import { Box } from '@react-native-material/core';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { useLocation } from 'react-router-dom';

import { dashboard } from '@assets/styles/pagesStyle';
import FamilySelector from '@shared/components/common/FamilySelector';
import WelcomeUserContainer from '@shared/components/common/LoggedInWelcome';
import PageContainer from '@shared/components/common/PageContainer';

import BureauTable from '../components/BureauTable';

export default function ManageCreditClient() {
  const location = useLocation();
  const { isFamilyMember, primary } = location.state || {};
  return (
    <PageContainer isClient={true}>
      <Box
        id="content"
        style={(dashboard.centeredContainer, styles.boxMarginFlex)}>
        <View style={styles.welcomeHeader} testID="ManageCredit">
          <Box>
            <WelcomeUserContainer />
            <Text variant="titleLarge">Bureaus</Text>
          </Box>
          <Box>
            <FamilySelector />
          </Box>
        </View>
        <BureauTable />
      </Box>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  boxMarginFlex: {
    flex: 1,
    margin: 10,
  },
  welcomeHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
    zIndex: 20,
  },
});
