/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import {
  ActivityIndicator,
  Button,
  Icon,
  SegmentedButtons,
  Surface,
  Text,
} from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import ModalComponent from '@shared/components/common/ModalComponent';
import { format } from '@shared/utils/displayFormats';
import { BureauCardsProps } from '@type/creditSummary.type';

import { creditBlockActions } from '../services/creditActions';

export default function BureauCards({ clientData }: BureauCardsProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const [value, setValue] = useState('24');
  const [selectedBureau, setSelectedBureau] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { activeId } = location.state || {};

  const auth = useAuth();
  const token = auth.user?.access_token || '';

  const bureauOrder = ['experian', 'equifax', 'transunion', 'chexsystems'];
  const bureauDisplayNames: { [key: string]: string } = {
    experian: 'Experian',
    equifax: 'Equifax',
    transunion: 'TransUnion',
    chexsystems: 'ChexSystems',
  };

  const sortedBureaus = clientData.bureaus.sort((a, b) => {
    const indexA = bureauOrder.indexOf(a.bureau.toLowerCase());
    const indexB = bureauOrder.indexOf(b.bureau.toLowerCase());

    return indexA - indexB;
  });

  const handlePress = async (bureau: string, displayStatus: string) => {
    try {
      const lowerCaseBureau = bureau.toLowerCase();
      if (displayStatus === 'Block') {
        setSelectedBureau(lowerCaseBureau);
        openModal();
      } else if (displayStatus === 'Unblock') {
        setLoading(true);
        const result = await creditBlockActions.blockBureau(
          activeId,
          lowerCaseBureau,
          token,
        );
        if (result.token || result.CaseId) {
          window.location.replace(window.location.href);
        }
      } else {
        alert('A process is currently in place. Please try again later.');
      }
    } catch (error) {
      console.error(`Error with ${displayStatus} action:`, error);
    }
  };

  const getButtonStyle = (status: any) => {
    switch (status) {
      case 'Block':
        return styles.buttonGreen;
      case 'Unblock':
        return styles.buttonRed;
      case '*Block':
      case '*Unblock':
        return styles.buttonYellow;
      default:
        return styles.buttonYellow;
    }
  };

  const openModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  const handleOptionChange = (option: string) => {
    const newDate = new Date();
    if (option === '24') {
      newDate.setHours(newDate.getHours() + 24);
    } else if (option === '48') {
      newDate.setHours(newDate.getHours() + 48);
    }
    setDate(newDate);
    setValue(option);
  };

  const handleModalSubmit = async () => {
    setLoading(true);
    try {
      if (selectedBureau) {
        const today = new Date().toLocaleDateString();
        const payload = {
          clientId: activeId,
          reblockDate: format.transformDateYYYYMMDD(date.toLocaleDateString()),
          fromDate: format.transformDateYYYYMMDD(today),
          toDate: format.transformDateYYYYMMDD(date.toLocaleDateString()),
        };
        const result = await creditBlockActions.unblockBureau(
          selectedBureau,
          payload,
          token,
        );
        if (result.token || result.CaseId) {
          window.location.replace(window.location.href);
        }
      }
      hideModal();
    } catch (error) {
      console.error('Error with unblocking:', error);
    }
  };
  return (
    <>
      <>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              {sortedBureaus.map((bureau, index) => (
                <Surface
                  key={index}
                  elevation={1}
                  style={styles.parentContainer}>
                  <View style={styles.iconContainer}>
                    {bureau.bureau.toLowerCase() === 'experian' ? (
                      <Icon
                        source={require('@assets/images/experian.png')}
                        size={70}
                      />
                    ) : bureau.bureau.toLowerCase() === 'equifax' ? (
                      <Icon
                        source={require('@assets/images/equifax.png')}
                        size={70}
                      />
                    ) : bureau.bureau.toLowerCase() === 'chexsystems' ? (
                      <Image
                        source={require('@assets/images/chexsystem.png')}
                        style={{ width: 200, height: 70 }}
                      />
                    ) : (
                      <Icon
                        source={require('@assets/images/transunion.png')}
                        size={70}
                      />
                    )}
                    <Text variant="titleLarge" style={styles.titleText}>
                      {bureauDisplayNames[bureau.bureau.toLowerCase()] ||
                        bureau.bureau}
                    </Text>
                  </View>
                  <View>
                    <Button
                      mode="contained"
                      onPress={() =>
                        handlePress(bureau.bureau, bureau.displayStatus)
                      }
                      style={getButtonStyle(bureau.displayStatus)}>
                      <View style={styles.buttonContent}>
                        {bureau.displayStatus === 'Block' ? (
                          <>
                            <Icon source="lock" size={40} />
                            <Text
                              variant="titleSmall"
                              style={styles.buttonText}>
                              {bureau.displayStatus}
                            </Text>
                          </>
                        ) : bureau.displayStatus === 'Unblock' ? (
                          <>
                            <Icon source="lock-open" size={40} />
                            <Text
                              variant="titleSmall"
                              style={styles.buttonText}>
                              {bureau.displayStatus}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Icon source="cog-sync" size={40} />
                            <Text
                              variant="titleSmall"
                              style={styles.buttonText}>
                              {bureau.displayStatus}ing...
                            </Text>
                          </>
                        )}
                      </View>
                    </Button>
                  </View>
                </Surface>
              ))}
            </View>
          </>
        )}
      </>

      {isModalVisible && (
        <ModalComponent
          modalTitle="Add Reblock Date"
          childrenElements={
            <>
              <Text style={styles.modalText}>
                Please choose your reblock date
              </Text>
              <SegmentedButtons
                value={value}
                onValueChange={handleOptionChange}
                buttons={[
                  {
                    value: '24',
                    label: '24 hours',
                    checkedColor: '#ED662C',
                    showSelectedCheck: true,
                  },
                  {
                    value: '48',
                    label: '48 hours',
                    checkedColor: '#ED662C',
                    showSelectedCheck: true,
                  },
                  {
                    value: 'custom',
                    label: 'Custom',
                    checkedColor: '#ED662C',
                    showSelectedCheck: true,
                  },
                ]}
              />
              {value === 'custom' && (
                <input
                  type="date"
                  value={date.toISOString().split('T')[0]}
                  onChange={e => handleDateChange(new Date(e.target.value))}
                  style={{ fontSize: 20, margin: 5 }}
                  min={new Date().toISOString().split('T')[0]}
                />
              )}
              {value && (
                <Text
                  style={{
                    textAlign: 'center',
                    marginBottom: 5,
                    marginTop: 10,
                  }}>
                  This account will be blocked on:{' '}
                  {`${date.getFullYear()}-${String(
                    date.getMonth() + 1,
                  ).padStart(2, '0')}-${String(date.getDate()).padStart(
                    2,
                    '0',
                  )}`}
                </Text>
              )}
            </>
          }
          visible={isModalVisible}
          closeModal={hideModal}
          submitModal={handleModalSubmit}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonGreen: {
    backgroundColor: '#EDF9EF',
    borderColor: '#EDF9EF',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    width: '8vw',
  },
  buttonRed: {
    backgroundColor: '#FFEDED',
    borderColor: '#FFEDED',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    width: '8vw',
  },
  buttonText: {
    textAlign: 'center',
  },
  buttonYellow: {
    backgroundColor: '#F0A50012',
    borderColor: '#F0A50012',
    borderRadius: 20,
    borderWidth: 1,
    margin: 2,
    width: '8vw',
  },
  iconContainer: {
    flexDirection: 'column',
  },
  modalText: {
    color: '#2B3674',
    fontSize: 15,
    fontWeight: '700',
    padding: 10,
  },
  parentContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 20,
    width: '100%',
  },
  titleText: {
    color: '#2B3674',
    fontWeight: '700',
  },
});
