import {
  KC_ISSUER,
  KC_CLIENT_ID,
  KC_REALM,
  KC_REDIRECT_URL_IOS,
  KC_REDIRECT_URL_WEB,
  API_ENDPOINT,
  API_VERSION,
  KC_SSO_AUTH,
  KC_SSO_VALIDATE_TOKEN_CLIENT_ID,
  KC_SSO_VALIDATE_TOKEN_CLIENT_SECRET,
  KC_SSO_ADMIN,
  KC_SSO_ADMIN_PASS,
} from '@env';

export const config = {
  kc: {
    issuer: KC_ISSUER,
    clientId: KC_CLIENT_ID,
    realm: KC_REALM,
    redirectUrl: {
      ios: KC_REDIRECT_URL_IOS,
      web: KC_REDIRECT_URL_WEB,
    },
  },
  api: {
    endpoint: API_ENDPOINT,
    version: API_VERSION,
  },
  kcOpenID: {
    sso_auth: KC_SSO_AUTH,
    realm: KC_REALM,
    clientId: KC_SSO_VALIDATE_TOKEN_CLIENT_ID,
    clientSecret: KC_SSO_VALIDATE_TOKEN_CLIENT_SECRET,
    ssoAdmin: KC_SSO_ADMIN,
    ssoAdminPass: KC_SSO_ADMIN_PASS,
  },
};
