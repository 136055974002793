/* eslint-disable no-console */
import React, { createContext, useReducer } from 'react';

import { creditSummaryService } from '@features/CreditBureaus/services/creditSummaryService';
import {
  ClientList,
  ClientState,
  ClientContextProps,
  ClientProviderProps,
} from '@type/client.type';

import { clientService } from '../services/clientsService';

const initialState: ClientState = {
  client: [],
  loading: false,
  error: null,
};

type Action =
  | { type: 'CLIENT_GET' }
  | { type: 'CLIENT_GET_SUCCESS'; payload: ClientList[] }
  | { type: 'CLIENT_GET_ERROR'; payload: string };

export const clientReducer = (
  state: ClientState,
  action: Action,
): ClientState => {
  switch (action.type) {
    case 'CLIENT_GET':
      return { ...state, loading: true };
    case 'CLIENT_GET_SUCCESS':
      return { ...state, loading: false, client: action.payload };
    case 'CLIENT_GET_ERROR':
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error(`Unhandled action type`);
  }
};

export const ClientContext = createContext<ClientContextProps | undefined>(
  undefined,
);

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(clientReducer, initialState);

  const getClient = async (token: string, contactId: string) => {
    dispatch({ type: 'CLIENT_GET' });
    try {
      const client = await clientService.getClient(token, contactId);
      dispatch({ type: 'CLIENT_GET_SUCCESS', payload: client });
      return client;
    } catch (error) {
      dispatch({
        type: 'CLIENT_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const getClientDetails = async (token: string, clientId: string) => {
    dispatch({ type: 'CLIENT_GET' });
    try {
      const clientDetails = await clientService.getClientDetails(
        token,
        clientId,
      );
      dispatch({ type: 'CLIENT_GET_SUCCESS', payload: clientDetails });
      return clientDetails;
    } catch (error) {
      dispatch({
        type: 'CLIENT_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  const getClientList = async (contactId: string, userToken: string) => {
    try {
      const clientList = await clientService.getClient(userToken, contactId);

      if (clientList) {
        const clientDetailsPromises = clientList.map(async client => {
          const [clientDetails, clientStatus] = await Promise.all([
            clientService.getClientDetails(userToken, client.id),
            creditSummaryService.getCreditSummary(client.id, userToken),
          ]);

          if (clientDetails) {
            const {
              address: { city, state },
            } = clientDetails;

            const status = clientStatus?.finalStatus || '';

            return {
              ...client,
              status,
              details: { city, state },
            };
          }
          return null;
        });

        const resolvedClientDetails = await Promise.all(clientDetailsPromises);
        const list = resolvedClientDetails.filter(Boolean) as ClientList[];

        dispatch({ type: 'CLIENT_GET_SUCCESS', payload: list });

        return list;
      }
    } catch (error) {
      dispatch({
        type: 'CLIENT_GET_ERROR',
        payload: JSON.stringify(error),
      });
    }
  };

  return (
    <ClientContext.Provider
      value={{ state, getClient, getClientDetails, getClientList }}>
      {children}
    </ClientContext.Provider>
  );
};
