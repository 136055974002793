/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { SafeAreaView, Text } from 'react-native';
import { useAuth } from 'react-oidc-context';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';

import { config } from '@configs/app.config';
import ForgotPassword from '@features/Authentication/pages/ForgotPassword';
import Login from '@features/Authentication/pages/Login';
import { ClientProvider } from '@features/Clients/context/ClientsContext';
import Clients from '@features/Clients/pages/Clients';
import { CreditSummaryProvider } from '@features/CreditBureaus/context/CreditSummaryContext';
import ManageCreditClient from '@features/CreditBureaus/pages/ManageCredit';
import { CreditLockProvider } from '@features/CreditLock/CreditLockContext';
import LockMyCredit from '@features/CreditLock/pages/LockMyCredit';
import FamilyProfiles from '@features/Family/pages/FamilyProfiles';
import ManageFamily from '@features/Family/pages/ManageFamily';
import { SupportProvider } from '@features/Support/context/SupportContext';
import Support from '@features/Support/pages/Support';
import Callback from '@shared/components/authWeb/callback';
import Protected from '@shared/components/authWeb/protectedPages';
import { windowsReload } from '@shared/components/common/helpers/windowsReload';
import LoadingSpinner from '@shared/components/layout/LoadingSpinner';
import { ClientResponseProvider } from '@shared/contexts/ClientResponseContext';

import NotFound from './NotFound';

const { issuer, clientId } = config.kc;

export default function App() {
  const auth = useAuth();
  auth && auth.activeNavigator;

  useEffect(() => {
    if (auth && auth.isAuthenticated && !auth.isLoading) {
      auth
        .querySessionStatus()
        .catch((error: { error: string; session_state: string }) => {
          if (error.error === 'login_required' && !error.session_state) {
            window.sessionStorage.removeItem(`oidc.user:${issuer}:${clientId}`);
            windowsReload();
          }
        });
    }
  });

  switch (auth && auth.activeNavigator) {
    case 'signinSilent':
    case 'signoutRedirect':
      return (
        <>
          <Text>Signing you in/out...</Text>
          <LoadingSpinner />
        </>
      );
  }

  const routes = createBrowserRouter([
    {
      path: '/',
      element: (auth && auth.isAuthenticated && !auth.isLoading && (
        <Navigate to="/clients" replace />
      )) || <Navigate to="/login" replace />,
    },
    {
      path: '/login',
      element: (auth && auth.isAuthenticated && !auth.isLoading && (
        <Navigate to="/clients" replace />
      )) || <Login />,
    },
    {
      path: '/logout',
      element: <Login />,
    },
    {
      path: '/callback',
      element: <Callback />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/clients',
      element: (
        <Protected>
          <ClientProvider>
            <Clients />
          </ClientProvider>
        </Protected>
      ),
    },
    {
      path: '/lock-my-credit',
      element: (
        <Protected>
          <CreditLockProvider>
            <LockMyCredit />
          </CreditLockProvider>
        </Protected>
      ),
    },
    {
      path: '/manage-credit',
      element: (
        <Protected>
          <ClientResponseProvider>
            <CreditSummaryProvider>
              <ManageCreditClient />
            </CreditSummaryProvider>
          </ClientResponseProvider>
        </Protected>
      ),
    },
    {
      path: '/manage-family',
      element: (
        <Protected>
          <ManageFamily />
        </Protected>
      ),
    },
    {
      path: '/family-profile',
      element: (
        <Protected>
          <FamilyProfiles />
        </Protected>
      ),
    },
    {
      path: '/support',
      element: (
        <Protected>
          <SupportProvider>
            <Support />
          </SupportProvider>
        </Protected>
      ),
    },
    {
      path: '/*',
      element: <NotFound />,
    },
  ]);

  return (
    <SafeAreaView>
      <RouterProvider router={routes} />
    </SafeAreaView>
  );
}
