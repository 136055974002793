/* eslint-disable react-native/no-color-literals */
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Avatar } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import {
  processFamily,
  renderFamilyData,
} from '@features/Family/helpers/familyTable.helper';
import { useFamily } from '@features/Family/hooks/familyHook';

export default function FamilySelector() {
  const { getFamily } = useFamily();
  const { getSingleFamily } = useFamily();
  const [_error, setError] = useState({});
  const [loading, setLoading] = useState(true);
  const [picklist, setPicklist] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [initials, setInitials] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { primary, familyMember, selectedFamily, activeId } =
    location.state || {};
  const auth = useAuth();
  useEffect(() => {
    async function fetchData() {
      try {
        if (familyMember) {
          setInitials(familyMember.firstName.charAt(0));
        } else {
          setInitials(primary.firstName.charAt(0));
        }
        const data = await getFamily(
          auth.user?.access_token || '',
          primary.key || '',
        );
        if (data) {
          const processedData = renderFamilyData(data);
          const familyProfile = processedData.map(family => family.profile);
          const familyDetails = await Promise.all(
            familyProfile.map(async id => {
              const singleFamily = await getSingleFamily(
                auth.user?.access_token || '',
                id,
              );
              return { singleFamily };
            }),
          );
          dataConverter(processFamily(familyDetails));
        }
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const dataConverter = (data: any) => {
    const groupedOptions = data.reduce((groups: any, member: any) => {
      const groupLabel = member.relationship;
      if (!groups[groupLabel]) {
        groups[groupLabel] = [];
      }
      groups[groupLabel].push({
        value: member.key,
        label: `${member.firstName} ${member.lastName}`,
        familyMember: member,
      });
      return groups;
    }, {});

    const primaryOption = {
      value: primary.key,
      label: `${primary.firstName} ${primary.lastName}`,
      familyMember: primary,
    };

    if (!groupedOptions['Primary']) {
      groupedOptions['Primary'] = [];
    }
    groupedOptions['Primary'].unshift(primaryOption);

    const picklistConvert = [
      {
        label: 'Primary',
        options: groupedOptions['Primary'],
      },
      ...Object.keys(groupedOptions)
        .filter(groupLabel => groupLabel !== 'Primary')
        .map(groupLabel => ({
          label: groupLabel,
          options: groupedOptions[groupLabel],
        })),
    ];

    setPicklist(picklistConvert);
  };

  const handleSelectOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleSelectChange = (selectedOption: any) => {
    const isPrimarySelected = selectedOption.value === `${primary.key}`;

    navigate(`/manage-credit`, {
      state: {
        primary: primary,
        familyMember: selectedOption.familyMember,
        isFamilyMember: !isPrimarySelected,
        selectedFamily: selectedOption.label,
        activeId: selectedOption.value,
      },
    });
    window.location.reload();
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <View style={{ marginRight: 10 }}>
              <Select
                options={picklist}
                isOpen={isOpen}
                placeholder={
                  selectedFamily || `${primary.firstName} ${primary.lastName}`
                }
                onMenuOpen={handleSelectOpen}
                onMenuClose={() => setIsOpen(false)}
                menuIsOpen={isOpen}
                onChange={handleSelectChange}
                styles={{
                  control: base => ({
                    ...base,
                    width: '10vw',
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    '&:hover': {
                      border: 'none',
                    },
                  }),
                  menu: base => ({
                    ...base,
                    backgroundColor: 'white',
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    display: 'none',
                  }),
                }}
              />
            </View>
            <View>
              <Avatar.Text
                size={35}
                label={initials}
                id="profile-loggedin"
                data-testid="profile-loggedin"
                role="status"
                onClick={handleSelectOpen}
              />
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    backgroundColor: 'transparent',
    borderRadius: 25,
    justifyContent: 'space-between',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    backgroundColor: 'white',
    borderRadius: 25,
    justifyContent: 'space-between',
    boxShadow: '1px 1px 1px 1px rgb(221 221 221)',
  },
  viewIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
};
